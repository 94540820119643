import { usePerformanceMonitor } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import {
    EffectComposer,
    DepthOfField,
    SMAA,
} from '@react-three/postprocessing';
import { useRef, useState } from 'react';
import { cameraLookAtVec } from '../../lookAtVec';

type Props = {
    enabled?: boolean;
};

const Postprocessing = ({ enabled = true }: Props) => {
    const dof = useRef<any>();

    const [dofHeight, setDofHeight] = useState(1024);

    usePerformanceMonitor({
        onChange: ({ fps }) => {
            if (fps > 60) {
                setDofHeight(1024);
            } else {
                setDofHeight(512);
            }
        },
    });

    useFrame(() => {
        if (dof.current) {
            dof.current.target = cameraLookAtVec;
        }
    });

    return (
        <EffectComposer
            multisampling={0}
            disableNormalPass={true}
            enabled={enabled}
        >
            <SMAA />
            <DepthOfField
                ref={dof}
                bokehScale={5}
                height={dofHeight}
                target={cameraLookAtVec}
            />
        </EffectComposer>
    );
};

export default Postprocessing;
