import { RecoilRoot } from 'recoil';
import Dom from './components/dom/Dom';
import WebGLWithGPUCheck from './components/webgl/WebGLWithGPUCheck';

function App() {
    return (
        <RecoilRoot>
            <WebGLWithGPUCheck />
            <Dom />
        </RecoilRoot>
    );
}

export default App;
