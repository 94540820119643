import { Letter, Vector } from './types';
import { supportsWebp } from './utils/supports-webp';

export const data: Record<
    number,
    {
        from: Vector;
        // to: Vector;
        cameraLookAt: Vector;
        message?: {
            title: string;
            text: string;
            bgUrl: string;
            classPostfix: string;
        };
    }
> = {
    1: {
        from: { x: 193, y: 78, z: 20 },
        // to: { x: 112, y: 43, z: 2 },
        // cameraLookAt: { x: 26, y: 23.37, z: 26.24 },
        cameraLookAt: { x: 9.2, y: 22.21, z: 30.25 },
    },
    2: {
        from: { x: 112, y: 43, z: 2 },
        // to: { x: 68.9, y: 27.9, z: -1.2 },
        cameraLookAt: { x: 9.2, y: 22.21, z: 30.25 },
        message: {
            title: 'Встречайте праздник',
            text: 'Магия новогодней ночи становится всё ближе и ближе. Этот волшебный сайт мы посвящаем нашим клиентам и фолловерам, а также достижениям этого года. Приятного путешествия!',
            bgUrl: `/img/message-bg.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'orange',
        },
    },
    3: {
        from: { x: 68.9, y: 27.9, z: -1.2 },
        // to: { x: 3.9, y: 21.6, z: -53.2 },
        cameraLookAt: { x: 10.43, y: 5.4, z: 8.95 },
        message: {
            title: 'Наш рост в 2022',
            text: 'Оптимизация бизнес-процессов, переход на Time and Materials, а также слава от международных наград 2021 года позволили нам вырасти в прибыли практически в два раза.',
            bgUrl: `/img/message-bg-blue.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'blue',
        },
    },
    4: {
        from: { x: 3.9, y: 21.6, z: -53.2 },
        // to: { x: -31.7, y: 21.4, z: -55.18 },
        cameraLookAt: { x: 5.25, y: 3.34, z: 3.55 },
    },
    5: {
        from: { x: -31.7, y: 21.4, z: -55.18 },
        // to: { x: -40.69, y: 10.98, z: 3.5 },
        cameraLookAt: { x: -15.75, y: 6.49, z: 0.93 },
        message: {
            title: 'Вкусные победы',
            text: 'Среди студийных «лакомств» уходящего года золотые статуэтки Рейтинга Рунета и Золотого сайта. Behance порадовал ленточкой за размещение в галерее графического дизайна.',
            bgUrl: `/img/message-bg-2.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'orange-2',
        },
    },
    6: {
        from: { x: -40.69, y: 10.98, z: 3.5 },
        // to: { x: -13.07, y: 16.9, z: 31.86 },
        cameraLookAt: { x: -14.38, y: 10.95, z: 33.5 },
        message: {
            title: 'Новые чипсы',
            text: 'В команде появились семь клёвых чипсинок, которые отлично дополнили нашу команду своими вкусными вкусами. Да, тут пряники, но вообще мы чипсинки.',
            bgUrl: `/img/message-bg-red.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'red',
        },
    },
    7: {
        from: { x: -13.07, y: 16.9, z: 31.86 },
        // to: { x: -39.34, y: 15.71, z: 36.2 },
        cameraLookAt: { x: -30.61, y: 0.9, z: 47.24 },
        message: {
            title: 'Кому печенья?',
            text: 'А вот и тарелка свежеиспеченных печенек для будущих релизов в 2023. Отложить для ваших проектов парочку вкусных cookies?',
            bgUrl: `/img/message-bg-green.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'green',
        },
    },
    8: {
        // from: { x: -39.34, y: 15.71, z: 36.2 },
        from: { x: -69.34, y: 65.71, z: 16.2 },
        // to: { x: -65.58, y: 66.13, z: 124.37 },
        cameraLookAt: { x: -24.81, y: 0, z: 70.94 },
        message: {
            title: 'Открытки для любимых',
            text: 'Встречайте главных обладателей наших печенек на сайтах. Спасибо, дорогие коллеги, что вы доверяете нам работать над вашим образом в вебе и создавать цифровые произведения искусства.',
            bgUrl: `/img/message-bg-red.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'red',
        },
    },
    9: {
        from: { x: -65.58, y: 66.13, z: 124.37 },
        // to: { x: 9.1, y: 111.54, z: 141.35 },
        // cameraLookAt: { x: -21.16, y: 0.06, z: 82.82 },
        cameraLookAt: { x: -21.16, y: 0, z: 82.82 },
        message: {
            title: '',
            text: 'Нажми на письмо!',
            bgUrl: `/img/message-bg-small.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix: 'orange-small',
        },
    },
    10: {
        from: { x: 9.1, y: 111.54, z: 141.35 },
        // to: { x: 82.18, y: 81.45, z: 144.7 },
        // cameraLookAt: { x: 3.46, y: 0.06, z: 86.27 },
        cameraLookAt: { x: 3.46, y: 0, z: 86.27 },
    },
    11: {
        from: { x: 82.18, y: 81.45, z: 144.7 },
        // to: { x: 102.65, y: 72.09, z: 108.92 },
        cameraLookAt: { x: 9.99, y: 0, z: 76.44 },
    },
    12: {
        from: { x: 102.65, y: 72.09, z: 108.92 },
        // to: { x: 31.41, y: 17.99, z: 74.03 },
        cameraLookAt: { x: 17.08, y: 0, z: 66.12 },
    },
    13: {
        from: { x: 31.41, y: 17.99, z: 74.03 },
        // to: { x: 72.53, y: 20.34, z: 5.53 },
        cameraLookAt: { x: 9.64, y: 16.69, z: 42 },
    },
    // 14: {
    //     from: { x: 72.53, y: 20.34, z: 5.53 },
    //     // to: { x: 142.02, y: 57.22, z: 34.25 },
    //     cameraLookAt: { x: 10.29, y: 20.62, z: 32.01 },
    // },
    // 15: {
    //     from: { x: 142.02, y: 57.22, z: 34.25 },
    //     // to: { x: 193.66, y: 78.86, z: 20.43 },
    //     cameraLookAt: { x: 10.29, y: 20.62, z: 32.01 },
    // },
};

export const lettersData: Letter[] = [
    {
        name: 'Daigo',
        from: 'Марии Андреенко',
        to: 'Daigo',
        text: `
        Желаем успехов и развития вашему бизнесу, новых открытий в научной деятельности и привнесения японских традиций в массы. Мы со своей стороны сделаем ваш сайт максимально крутым для помощи в достижении этих целей.
        `,
        logoUrl: '/img/logos/daigo.svg',
    },
    {
        name: 'Osmo_Legal',
        from: 'Марии Андреенко',
        to: 'Sinai (osmolegal)',
        text: `
        Спасибо вам за ваше терпение и вовлеченность в рабочий процесс. Мы будем продолжать совместную работу над улучшением и развитием проекта и в следующем году запустим с вами еще больше фич! Успехов и процветания вашему бизнесу.
        `,
        logoUrl: '/img/logos/sinai.svg',
    },
    {
        name: 'Treaton',
        from: 'Марии Андреенко',
        to: 'Treaton',
        text: `
        Мы рады, что участвуем в создании и развитии такого проекта, который помогает врачам и спасает человеческие жизни. Успехов вам в продвижении вашей продукции и новых научных и технологических открытий.
        `,
        logoUrl: '/img/logos/treaton.svg',
    },
    {
        name: 'Binar',
        from: 'Марии Андреенко',
        to: 'Binar',
        text: `
        Уважаемые партнеры, примите искренние поздравления с Новым Годом. Желаем, чтобы Новый год был годом расцвета и процветания, чтобы новые жизненные рубежи преодолевались непринужденно и с легкостью, чтоб в наступающем году сбывались все мечты!
        `,
        logoUrl: '/img/logos/binar.svg',
    },
    {
        name: 'Gurmix',
        from: 'Марии Андреенко',
        to: 'Gurmix',
        text: `
        Пусть вас следующий год будет таким же ярким и креативным как проект, который у нас совместно получился. Желаем вам успехов в развитии и открытии для людей новых вкусовых сочетаний и блюд) Всегда рады помочь в осуществлении и воплощении на сайте ваших вкусных идей
        `,
        logoUrl: '/img/logos/gurmix.svg',
    },
    {
        name: 'TBD',
        from: 'Марии Андреенко',
        to: 'TBD team',
        text: `
        Мы всегда рады проектам, которые с нами на одной волне креативности. Желаем вам больше ярких идей и энергии на их воплощение, развития и успеха вашему бизнесу, а также алмазных дождей в виде новых клиентов!
        `,
        logoUrl: '/img/logos/tbd.png',
    },
    {
        name: 'Malinovka',
        from: 'Марии Андреенко',
        to: 'Дары Малиновки',
        text: `
        Поздравляем вас с наступающим праздником. Желаем вам расширения сетей ваших магазинов и пахотных земель, новых идей в развитии и инноваций в сфере выращивания растений и животноводства.
        `,
        logoUrl: '/img/logos/malinovka.svg',
    },
    {
        name: 'OS',
        from: 'Марии Андреенко',
        to: 'Опоры Сибири',
        text: `
        В новый год с новым сайтом! Мы долго разрабатывали и совершенствовали контент для проекта и благодарим вас за возможность детально проработать и воплотить все наши идеи. Надеемся новый сайт будет надежной поддержкой вашего бизнеса, как и ваши мачты являются надежной опорой для ваших клиентов.
        `,
        logoUrl: '/img/logos/opora.svg',
    },
    {
        name: 'SGC',
        from: 'Марии Андреенко',
        to: 'СГК',
        text: `
        Мы рады стать частью проектов, которые приносят пользу столь большому количеству людей. Благодарим за возможность решать нестандартные задачи, опережающие рынок! Надеемся на дальнейшее плодотворное сотрудничество!
        `,
        logoUrl: '/img/logos/sgc.svg',
    },
    {
        name: 'Gt-energo',
        from: 'Марии Андреенко',
        to: 'ГТ Энерго',
        text: `
        Современной компании – современный сайт! Мы рады что смогли воплотить ваши идеи и при этом сделать сайт удобный и понятный для пользователя. Желаем вашей компании успехов в развитии и лояльных клиентов, надеемся новый сайт помогает вам в этом и закрывает запросы пользователей.
        `,
        logoUrl: '/img/logos/gt-energo.svg',
    },
    {
        name: 'AAG',
        from: 'Марии Андреенко',
        to: 'AAG',
        text: `
        Обновление сайта и создание контента для передачи вашего нового стиля стало для нас интересной и важной задачей…Мы включили наш креатив и движки разработчиков на максимум, чтобы выполнить максимально много задач в короткие сроки. В новом году нас ждут еще больше страниц, и мы рады воплощать все ваши креативные задумки.
        `,
        logoUrl: '/img/logos/aag.svg',
    },
    {
        name: '0101',
        from: 'Марии Андреенко',
        to: '0101',
        text: `
        Вы крутые, создаете шикарные продукты для своих заказчиков. Да-да, мы подглядываем за вами. Мы были рады стать частью воплощения вашей индивидуальности с помощью сайта. Желаем вам новых крутых заказчиков, которые позволят вам реализовать все свои задумки и показать всю вашу креативную мощь!
        `,
        logoUrl: '/img/logos/0101.svg',
    },
    {
        name: 'Charruti',
        from: 'Марии Андреенко',
        to: 'Charutti',
        text: `
        Мы благодарны, что у нас есть возможность стать частью такого масштабного проекта, нацеленного на прекрасную половину человечества. Желаем вам процветания, вдохновения и новых чарующих коллекций.
        `,
        logoUrl: '/img/logos/charutti.svg',
    },
    {
        name: 'NJC',
        from: 'Марии Андреенко',
        to: 'Юридическая коллегия',
        text: `
        Ваш серьёзный, основательный подход к делу и искреннее желание помочь каждому вашему клиенту восхищают! Желаем вам процветания, финансового благополучия, а также всегда следовать по белой полосе на зебре возможностей!
        `,
        logoUrl: '/img/logos/nuk.svg',
    },
    {
        name: 'Art-life',
        from: 'Марии Андреенко',
        to: 'АртЛайф',
        text: `
        Мы рады создавать креативные и технологичные проекты вместе с вами! Ваша заинтересованность в собственном деле, энтузиазм и заряд делать мир лучше не могут не вдохновлять. Желаем вам процветания и новых интересных проектов! Не останавливайтесь на достигнутом!
        `,
        logoUrl: '/img/logos/artlife.svg',
    },
    {
        name: 'Composit',
        from: 'Марии Андреенко',
        to: 'Kompozit',
        text: `
        Простота, стиль, лёгкость. Именно так мы с вами воплотили идею сайта, который увидит весь мир! Благодарим вас за неподдельный интерес к процессам разработки сайта, а также за открытость новым идеям и экспериментам. Желаем вам успешного завоевания зарубежного рынка, развития текущего, стабильного роста и благополучия!
        `,
        logoUrl: '/img/logos/kompozit.svg',
    },
    {
        name: 'Sportex',
        from: 'Марии Андреенко',
        to: 'Спортэкс',
        text: `
        Благодарим вас за возможность сделать креативный продукт для крутых людей, живущих своей идеей. Надеемся на продолжение работ в будущем. Желаем вам вдохновения, новых молодёжных проектов и полных залов счастливых учеников!
        `,
        logoUrl: '/img/logos/sportex.svg',
    },
    {
        name: 'IIko',
        from: 'Марии Андреенко',
        to: 'iiko',
        text: `
        Благодарим вас и 2022 за то, что мы начали совместную работу! Мы уверены, что 2023 год для нас с вами будет очень продуктивным и волнительным на новые достижения и фичи! Тот случай когда при походе в кафе, бар или ресторан видишь на экранах логотип iiko и радуешься, и гордишься, что работаешь с такой потрясающей компанией))
        `,
        logoUrl: '/img/logos/iiko.svg',
    },
    {
        name: 'Sheregesh',
        from: 'Марии Андреенко',
        to: 'Шерегеш',
        text: `
        Желаем вам покорения новых вершин, побольше снега зимой и много солнечных дней летом и, конечно же, неиссякаемый поток туристов и энергии! Благодарим за совместную работу в уходящем году и смотрим в будущее в предстоящем 2023 году! 
        `,
        logoUrl: '/img/logos/sheregesh.svg',
    },
    {
        name: 'RAY',
        from: 'Марии Андреенко',
        to: 'Рэй',
        text: `
        С новым годом, уважаемые коллеги! Мы уверены, что совместный проект откроет новые возможности и, самое главное, принесет заказы для вашей компании! Мы очень рады что в 2022 году мы с вами познакомились! Желаю вам стабильности и расширения портфолио!
        `,
        logoUrl: '/img/logos/ray.svg',
    },
    {
        name: 'Darkside',
        from: 'Марии Андреенко',
        to: 'Darkside',
        text: `
        Тот случай, когда продукт, который присутствует на всех праздниках и отдыхах студии становится нашим клиентом. Два похожих но таких разных проекта с уникальными стилями и философией. Два классных и креативных сайта, которые позволили нам проявить наш креатив и возможности анимаций) Будем рады и дальше воплощать ваши идеи))

        `,
        logoUrl: '/img/logos/darkside.svg',
    },
    {
        name: 'Dvijenie',
        from: 'Марии Андреенко',
        to: 'Движение',
        text: `
        Движение – это жизнь! Тот случай, когда дорогой клиент стал настолько близок нашей студии, что мы считаем себя одной, большой и дружной семьей! Желаем вам никогда не останавливаться и всегда идти вперед!
        `,
        logoUrl: '/img/logos/dvizh.svg',
    },
    {
        name: 'Skinstric',
        from: 'Nikita Gruzinenko',
        to: 'Skinstric',
        text: `
        One of our favorite projects in the studio. We wish you a happy new year and we hope that the upcoming year will be prosperous! We used our best practice to create a mutual project that we are eager to see in action and try your best practice on ourselves! Thank you and wishing you all the best! Hoping for new ideas next year!
        `,
        logoUrl: '/img/logos/skinstric.svg',
    },
    {
        name: 'ORION',
        from: 'Марии Андреенко',
        to: 'ОРИОН',
        text: `
        Желаем вам новых звезд в новом году – ярких, положительных и приносящих успех всей компании! Надеемся, что 2023 год подарит нам более тесную и плодотворную работу!
        `,
        logoUrl: '/img/logos/orion.svg',
    },
    {
        name: 'Paper_Planes',
        from: 'Марии Андреенко',
        to: 'PaperPlanes',
        text: `
        Сердечно поздравляем с Новым годом Пусть он будет полон новых идей, взлетов и перспектив! Открывающихся возможностей, финансовых успехов!
        `,
        logoUrl: '/img/logos/paperplanes.svg',
    },
    {
        name: 'Eldis',
        from: 'Марии Андреенко',
        to: 'Элдис',
        text: `
        Поздравляем Вас с Новым годом! Пусть ваша деятельность всегда будет успешной и приносящей постоянно растущий доход. Желаем благонадежных и ответственных партнеров, постоянных клиентов и здоровой обстановки в коллективе.
        `,
        logoUrl: '/img/logos/eldis.svg',
    },
    {
        name: 'Dianet',
        from: 'Марии Андреенко',
        to: 'Дианэт',
        text: `
        Желаем вам, уважаемые клиенты, чтобы наступающий год стал годом побед, взлетов и достижений. Пусть во всех сферах вашей жизни присутствует удача, а успех покровительствует в ваших делах. Благодарим вас за доверие и всегда рады видеть вас в числе наших клиентов.
        `,
        logoUrl: '/img/logos/dianet.svg',
    },
    {
        name: 'Bati',
        from: 'Марии Андреенко',
        to: 'Бати',
        text: `
        Коллеги, пусть наступающий год подарит вам замечательное настроение и станет прекрасным, плодотворным периодом для достижения всех целей и планов!
        `,
        logoUrl: '/img/logos/bati.svg',
    },
    {
        name: 'Devision',
        from: 'Марии Андреенко',
        to: 'DeVision',
        text: `
        С Новым годом спешим вас поздравить и пожелать успешной деятельности! Пусть в наступающем году откроются новые перспективы, а все начатые проекты обязательно оправдают ожидания! Желаем роста прибыли, укрепления партнерских отношений и доверия у клиентов!
        `,
        logoUrl: '/img/logos/devision.svg',
    },
    {
        name: 'MDIS',
        from: 'Марии Андреенко',
        to: 'МДИС',
        text: `
        С Новым годом! Пусть этот год станет стартовой площадкой для новых взлётов, достижений, открытий, побед! Пусть в новом году любое начинание будет обречено на неоспоримый успех, а планы легко и точно реализуются в конкретные дела и мероприятия.
        `,
        logoUrl: '/img/logos/mdis.svg',
    },
    {
        name: 'RSI',
        from: 'Марии Андреенко',
        to: 'РСИ',
        text: `
        С Новым годом, дорогие партнеры! Желаем  вам за этот новый год добиться невероятно высоких результатов, повысить планку процветания до максимального уровня, найти массу новых возможностей для реализации всех перспективных идей.
        `,
        logoUrl: '/img/logos/rsi.svg',
    },
    {
        name: 'JSK',
        from: 'Марии Андреенко',
        to: 'ЖСК',
        text: `
        Уважаемые наши партнеры! Новый год — самый долгожданный праздник. Пусть он принесет вам процветание, успех, новые проекты, стабильной удачи, хороших доходов, новых, плодотворных идей!
        `,
        logoUrl: '/img/logos/jsk.svg',
    },
    {
        name: 'Dipol',
        from: 'Марии Андреенко',
        to: 'Диполь',
        text: `
        Желаем совершить прорыв и вывести отечественную электронную промышленность на новый уровень, благодаря внедрению собственных передовых инженерно-технических решений. Мы же в свою очередь поможем вам обо всем этом красиво и технологично рассказать в интернете.
        `,
        logoUrl: '/img/logos/dipaul.svg',
    },
    {
        name: 'A101',
        from: 'Марии Андреенко',
        to: 'A101',
        text: `
        Желаем в Новом году заложить крепкий фундамент, дать уверенный старт продаж и наметить новые земли для освоения. Удачи вам и вашим проектам!
        `,
        logoUrl: '/img/logos/a101.svg',
    },
];
