import { AdditiveBlending, Float32BufferAttribute } from 'three';
import { PointsProps, useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import { supportsWebp } from '../../utils/supports-webp';

type Props = PointsProps & {
    count: number;
    minRange?: number;
    maxRange?: number;
    minHeight?: number;
    speed?: number;
    size?: number;
};

function Particles({
    count,
    maxRange = 300,
    minRange = maxRange / 2,
    minHeight = 0,
    speed = 1,
    size = 2.5,
    ...props
}: Props) {
    const particles = useRef<any>(null);
    const map = useTexture(
        `/snowflake-png-transparent-19.${supportsWebp ? 'webp' : 'png'}`,
    );

    const position = useMemo(() => {
        const positions = [];

        for (let i = 0; i < count; i++) {
            positions.push(
                Math.floor(Math.random() * maxRange - minRange),
                Math.floor(Math.random() * minRange - minHeight),
                Math.floor(Math.random() * maxRange - minRange),
            );
        }

        return new Float32BufferAttribute(positions, 3);
    }, [count, minRange, maxRange, minHeight]);

    // const opacity = useMemo(() => {
    //     const opacities = [];

    //     for (let i = 0; i < count; i++) {
    //         opacities.push(
    //             1,
    //             // Math.floor(Math.random() * minRange - minHeight),
    //             // Math.floor(Math.random() * maxRange - minRange),
    //         );
    //     }

    //     return new Float32BufferAttribute(opacities, 1);
    // }, [count]);

    const velocity = useMemo(() => {
        const velocities = [];

        for (let i = 0; i < count; i++) {
            velocities.push(
                Math.floor(Math.random() * 6 - 3) * 0.1 * speed,
                Math.floor(Math.random() * 5 + 0.12) * 0.18 * speed,
                Math.floor(Math.random() * 6 - 3) * 0.1 * speed,
            );
        }

        return new Float32BufferAttribute(velocities, 3);
    }, [count, speed]);

    useFrame(() => {
        if (particles.current) {
            for (let i = 0; i < count * 3; i += 3) {
                particles.current.geometry.attributes.position.array[i] -=
                    particles.current.geometry.attributes.velocity.array[i];
                particles.current.geometry.attributes.position.array[i + 1] -=
                    particles.current.geometry.attributes.velocity.array[i + 1];
                particles.current.geometry.attributes.position.array[i + 2] -=
                    particles.current.geometry.attributes.velocity.array[i + 2];
                // particles.current.geometry.attributes.opacity.array[i] -= 0.1;

                if (
                    particles.current.geometry.attributes.position.array[
                        i + 1
                    ] < -minRange
                ) {
                    particles.current.geometry.attributes.position.array[i] =
                        Math.floor(Math.random() * maxRange - minRange);
                    particles.current.geometry.attributes.position.array[
                        i + 1
                    ] = Math.floor(Math.random() * minRange - minHeight);
                    particles.current.geometry.attributes.position.array[
                        i + 2
                    ] = Math.floor(Math.random() * maxRange - minRange);
                }
            }

            particles.current.geometry.attributes.position.needsUpdate = true;
        }
    });

    return (
        <points ref={particles} {...props}>
            <bufferGeometry attributes={{ position, velocity }} />
            <pointsMaterial
                size={size}
                map={map}
                blending={AdditiveBlending}
                depthTest={false}
                transparent
                opacity={1}
            />
        </points>
    );
}

export default Particles;
