import { useState, useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import LitPopup from 'lit-popup';
import classNames from 'classnames';
import { useOpenedPopupsState } from '../../../atoms/opened-popups';

type Props = React.HTMLAttributes<HTMLElement> & {
    containerClass?: string;
    name: string;
    onOpen?: (instance: LitPopup) => void;
    onOpenComplete?: (instance: LitPopup) => void;
    onClose?: (instance: LitPopup) => void;
    onCloseComplete?: (instance: LitPopup) => void;
    opened?: boolean;
    overlay?: boolean;
    wrapperChildren?: ReactNode;
};

const NO_SCROLL_CLASS = 'no-scroll';
const root = document.querySelector('#portal-root');

const LitPopupComponent = ({
    children,
    wrapperChildren,
    containerClass = '',
    name,
    onOpen,
    onOpenComplete,
    onClose,
    onCloseComplete,
    opened,
    overlay,
    ...props
}: Props) => {
    const leaveDurationInSeconds = 0.3;
    const closeAnimationTimeout = useRef<NodeJS.Timeout>();
    const [instance, setInstance] = useState<LitPopup | null>(null);
    const [openedPopups, setOpenedPopups] = useOpenedPopupsState();

    useEffect(() => {
        if (instance) {
            if (openedPopups.includes(name)) {
                if (!instance.isOpen) {
                    instance.open();
                }
            } else {
                if (instance.isOpen) {
                    instance.close();
                }
            }
        }
    }, [openedPopups, instance, name]);

    useEffect(() => {
        const instance = new LitPopup(name, {
            onOpen: () => {
                document.body.classList.add(NO_SCROLL_CLASS);
                onOpen?.(instance);
            },
            onOpenComplete: () => {
                const focusableOnOpenElement =
                    instance.el.querySelector<HTMLElement>(
                        '[data-focus-on-popup-open]',
                    );
                setTimeout(
                    () =>
                        focusableOnOpenElement?.focus({ preventScroll: true }),
                    50,
                );
                onOpenComplete?.(instance);
                setOpenedPopups((prevPopups) =>
                    Array.from(new Set([...prevPopups, name])),
                );
            },
            onClose: () => {
                onClose?.(instance);
            },
            onCloseComplete: () => {
                onCloseComplete?.(instance);
                document.body.classList.remove(NO_SCROLL_CLASS);
                setOpenedPopups((prevPopups) =>
                    prevPopups.filter((popupName) => popupName !== name),
                );
            },
            openAnimation: () =>
                new Promise((resolve) => {
                    clearTimeout(closeAnimationTimeout.current);
                    closeAnimationTimeout.current = setTimeout(
                        resolve,
                        leaveDurationInSeconds * 1000,
                    );
                }),
            closeAnimation: () =>
                new Promise((resolve) => {
                    clearTimeout(closeAnimationTimeout.current);
                    closeAnimationTimeout.current = setTimeout(
                        resolve,
                        leaveDurationInSeconds * 1000,
                    );
                }),
        });
        setInstance(instance);

        return () => {
            clearTimeout(closeAnimationTimeout.current);
            instance.destroy();
            setInstance(null);
            document.body.classList.remove(NO_SCROLL_CLASS);
        };
    }, [
        name,
        onClose,
        onCloseComplete,
        onOpen,
        onOpenComplete,
        setOpenedPopups,
    ]);

    useEffect(() => {
        if (instance) {
            if (opened) {
                if (!instance.isOpen) {
                    instance.open();
                }
            } else if (instance.isOpen) {
                instance.close();
            }
        }
    }, [instance, opened]);

    const Component = (
        <div
            {...props}
            className={classNames('lit-popup', props.className, {
                'lit-popup--opened': opened,
            })}
            data-lit-popup={name}
            data-lit-popup-preset="default"
            data-lit-popup-ignore-inert
        >
            {overlay && (
                <div
                    className="lit-popup-overlay"
                    data-lit-popup-close={name}
                ></div>
            )}
            {wrapperChildren}
            <div className={classNames('lit-popup-container', containerClass)}>
                {children}
            </div>
        </div>
    );

    return root ? createPortal(Component, root) : null;
};

export default LitPopupComponent;
