import { useSoundConfigState } from '../../../atoms/sound-config';
import SoundOnSVG from '../../../svg/sound-on.svg';
import SoundOffSVG from '../../../svg/sound-off.svg';
import classNames from 'classnames';
import { supportsWebp } from '../../../utils/supports-webp';

const SoundConfiguratorScreen = () => {
    const [soundConfig, setSoundConfig] = useSoundConfigState();

    return (
        <div
            className={classNames('sound-configurator-screen', {
                'sound-configurator-screen--leaving': soundConfig.picked,
            })}
        >
            <div className="sound-configurator-screen-inner-el">
                <img
                    src={`/img/paints/red.${supportsWebp ? 'webp' : 'jpg'}`}
                    width={1920}
                    height={960}
                    alt=""
                    className="sound-configurator-screen__bg"
                />
                <div className="sound-configurator-screen__text">
                    Рекомендуем включить звук для лучшего опыта
                </div>
                <div className="sound-configurator-screen__actions">
                    <button
                        className="sound-configurator-screen__action"
                        aria-label="Включить звук"
                        onClick={() =>
                            setSoundConfig({ picked: true, enabled: true })
                        }
                    >
                        <SoundOnSVG />
                    </button>
                    <button
                        className="sound-configurator-screen__action"
                        aria-label="Выключить звук"
                        onClick={() =>
                            setSoundConfig({ picked: true, enabled: false })
                        }
                    >
                        <SoundOffSVG />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SoundConfiguratorScreen;
