// @ts-nocheck

import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Points } from 'three';

function Sparkles({ position, amp = 1, size = 1, ...props }: any) {
    const sprites = useTexture(
        [...Array(5)].map((_, i) => `/sparkles/${i}.png`),
    );
    const points = useRef<Points>(null);
    const t = useRef(Infinity);
    const initialAttribute = useRef<any>();

    useEffect(() => {
        const timer = setInterval(() => {
            requestAnimationFrame(() => {
                if (points.current) {
                    // First time around, save initial point positions
                    if (!initialAttribute.current) {
                        initialAttribute.current =
                            points.current.geometry.attributes.position.clone();
                    }
                    // Then copy and randomize
                    const positionAttribute = points.current.geometry.attributes
                        .position as any;
                    for (let i = 0; i < positionAttribute.array.length; ++i) {
                        positionAttribute.array[i] =
                            initialAttribute.current.array[i];
                        positionAttribute.array[i] +=
                            40 * size * (Math.random() - 0.5);
                    }
                    positionAttribute.needsUpdate = true;
                    t.current = 0;
                }
            });
        }, 100 / amp);
        return () => clearInterval(timer);
    }, [amp, size]);

    // Animate sprite texture
    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            requestAnimationFrame(() => {
                points.current.material.map = sprites[i++ % sprites.length];
            });
        }, 100 / amp);
        return () => clearInterval(interval);
    }, [sprites, amp]);

    useFrame((_, delta) => {
        t.current += delta * 2;
        points.current.scale.setScalar(t.current);
        points.current.material.size = 2 * size - t.current;
    });

    return (
        <points ref={points} position={position} {...props}>
            <circleBufferGeometry args={[1, 16]} />
            <pointsMaterial
                size={0.5}
                map={sprites[0]}
                transparent
                depthTest={false}
                color="#FFF8E5"
            />
        </points>
    );
}

export default Sparkles;
