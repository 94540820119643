import { createRef, RefObject, useRef } from 'react';
import { useActiveLetterState } from '../../atoms/active-letter';
import { usePreloaderReadyState } from '../../atoms/preloader-ready';
import { useScreensState } from '../../atoms/screens';
import { useSoundConfigState } from '../../atoms/sound-config';
import { data, lettersData } from '../../data';
import { useDebounce } from '../../hooks/use-debounce';
import Header from './Header';
import LetterPopup from './LetterPopup';
import Menu from './Menu';
import Message from './Message';
import Preloader from './Preloader';
import SoundConfiguratorScreen from './SoundConfiguratorScreen';
import ProgressRound from './ProgressRound';
import Sounds from './Sounds';
import classNames from 'classnames';

const Dom = () => {
    const messageRef = useRef<RefObject<HTMLDivElement>[]>([]);
    messageRef.current = Object.keys(data).map(
        (key) =>
            messageRef.current[parseInt(key)] || createRef<HTMLDivElement>(),
    );

    const [activeLetterName] = useActiveLetterState();
    const [preloaderReady] = usePreloaderReadyState();
    const debouncedPreloaderReady = useDebounce(preloaderReady, 1000);
    const [config] = useSoundConfigState();
    const debouncedSoundConfig = useDebounce(config, 1000);
    const [currentScreen] = useScreensState();

    const activeLetterData = lettersData.find(
        (letter) => letter.name === activeLetterName,
    );
    const debouncedActiveLetterData = useDebounce(activeLetterData, 300);
    const letterData = !!activeLetterData
        ? activeLetterData
        : debouncedActiveLetterData;

    return (
        <main className="main">
            {!debouncedPreloaderReady && <Preloader />}
            {!debouncedSoundConfig.picked && <SoundConfiguratorScreen />}

            <Header />

            {Object.keys(data).map(
                (key) =>
                    data[parseInt(key)].message && (
                        <Message
                            key={key}
                            ref={messageRef.current[parseInt(key)]}
                            title={data[parseInt(key)].message?.title || ''}
                            text={data[parseInt(key)].message?.text || ''}
                            bgUrl={data[parseInt(key)].message?.bgUrl}
                            classPostfix={
                                data[parseInt(key)].message?.classPostfix || ''
                            }
                            visible={parseInt(key) === currentScreen}
                        />
                    ),
            )}

            <div
                className={classNames('last-message', {
                    'is-visible': currentScreen === 13,
                })}
            >
                <span>Happy</span>
                <span>NewYear!</span>
            </div>

            <ProgressRound />

            <LetterPopup
                opened={!!activeLetterData}
                from={letterData?.from}
                to={letterData?.to}
                logoUrl={letterData?.logoUrl}
                text={letterData?.text}
            />

            <Menu />

            <Sounds />
        </main>
    );
};

export default Dom;
