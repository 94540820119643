import classNames from 'classnames';
import { HTMLAttributes, memo, useCallback } from 'react';
import { useOpenedPopupsState } from '../../../atoms/opened-popups';
import { supportsWebp } from '../../../utils/supports-webp';
import LitPopup from '../LitPopup';
import ProgressPath from './ProgressPath';

export interface Props extends HTMLAttributes<HTMLElement> {
    opened?: boolean;
    from?: string;
    to?: string;
    text?: string;
    logoUrl?: string;
}

const NAME = 'menu';

const Menu = ({ opened = false, ...props }: Props) => {
    const [_, setOpenedPopups] = useOpenedPopupsState();

    const onButtonClick = useCallback(
        () =>
            setOpenedPopups((prevPopups) =>
                prevPopups.filter((name) => name !== NAME),
            ),
        [setOpenedPopups],
    );

    return (
        <LitPopup
            {...props}
            className={classNames('menu', props.className)}
            name={NAME}
            opened={opened}
            wrapperChildren={
                <img
                    src={`/img/paints/red.${supportsWebp ? 'webp' : 'jpg'}`}
                    width={1920}
                    height={960}
                    alt=""
                    className="menu-popup__bg"
                    loading="lazy"
                    decoding="async"
                />
            }
        >
            <div className="wrapper menu-content">
                <ProgressPath onButtonClick={onButtonClick} />

                <div className="menu-bottom">
                    <div className="menu-bottom__left">Cделано в Сhipsa</div>
                    <div className="menu-bottom__right">2022 год</div>
                </div>
            </div>
        </LitPopup>
    );
};

export default memo(Menu);
