import { useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { memo, useMemo, useRef } from 'react';
import { Mesh, Vector3 } from 'three';
import { useScreensState } from '../../atoms/screens';
import { data } from '../../data';
import { cameraLookAtVec } from '../../lookAtVec';

const interpolate = (a: number, b: number, frac: number) => a + (b - a) * frac;

const isTouch = matchMedia('(pointer: coarse)').matches;
const fallbackFrom = { x: 72.53, y: 20.34, z: 5.53 };
const fallback = data[1];

const FlyingCamera = () => {
    const ghostMesh = useRef<Mesh>(null);

    const scroll = useScroll();
    (window as any)._scrollEl = scroll.el;
    const [currentScreen, setCurrentScreen] = useScreensState();
    const cameraPosVec = useMemo(() => new Vector3(), []);

    useFrame((state) => {
        const scrollOffset = scroll.offset;
        (window as any)._scrollOffset = scrollOffset;

        for (let i = 0; i < scroll.pages; i++) {
            if (
                scrollOffset >= i / scroll.pages &&
                scrollOffset < (i + 1) / scroll.pages
            ) {
                if (data[i + 1]) {
                    if (currentScreen !== i + 1) {
                        setCurrentScreen(i + 1);
                    }

                    const range = scroll.range(
                        i / scroll.pages,
                        1 / scroll.pages,
                    );

                    // fallbackFrom

                    state.camera.position.lerp(
                        cameraPosVec.set(
                            interpolate(
                                data[i + 1].from.x,
                                // (data[i + 2] || fallback).from.x,
                                (data[i + 2] ? data[i + 2].from : fallbackFrom)
                                    .x,
                                range,
                            ),
                            interpolate(
                                data[i + 1].from.y,
                                // (data[i + 2] || fallback).from.y,
                                (data[i + 2] ? data[i + 2].from : fallbackFrom)
                                    .y,
                                range,
                            ),
                            interpolate(
                                data[i + 1].from.z,
                                // (data[i + 2] || fallback).from.z,
                                (data[i + 2] ? data[i + 2].from : fallbackFrom)
                                    .z,
                                range,
                            ),
                        ),
                        isTouch ? 0.1 : 0.08,
                    );

                    if (ghostMesh.current) {
                        ghostMesh.current.position.lerp(
                            cameraLookAtVec.set(
                                interpolate(
                                    data[i + 1].cameraLookAt.x,
                                    (data[i + 2] || fallback).cameraLookAt.x,
                                    range,
                                ),
                                interpolate(
                                    data[i + 1].cameraLookAt.y,
                                    (data[i + 2] || fallback).cameraLookAt.y,
                                    range,
                                ),
                                interpolate(
                                    data[i + 1].cameraLookAt.z,
                                    (data[i + 2] || fallback).cameraLookAt.z,
                                    range,
                                ),
                            ),
                            isTouch ? 0.1 : 0.06,
                        );

                        state.camera.lookAt(ghostMesh.current.position);
                    }
                }

                break;
            }
        }
    });

    return <mesh ref={ghostMesh} name="ghost" />;
};

export default memo(FlyingCamera);
