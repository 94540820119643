import { memo, useCallback, useLayoutEffect, useState } from 'react';
import { data } from '../../../data';
import ProgressPathDesktop from './ProgressPathDesktop';
import ProgressPathMobile from './ProgressPathMobile';

type Props = {
    onButtonClick?: () => void;
};

const totalScreens = Object.keys(data).length;

const ProgressPath = ({ onButtonClick }: Props) => {
    const [isMobile, setIsMobile] = useState(false);

    useLayoutEffect(() => {
        const onResize = () => {
            setIsMobile(matchMedia('(max-width: 767px)').matches);
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    const onClick = useCallback(
        (travelToScreen: number) => {
            requestAnimationFrame(() => {
                (window as any)._scrollEl.scrollTop =
                    1 +
                    ((window as any)._scrollEl.scrollHeight / totalScreens) *
                        (travelToScreen - 1);

                onButtonClick?.();
            });
        },
        [onButtonClick],
    );

    return (
        <div className="progress-path-svg-wrapper">
            {isMobile ? (
                <ProgressPathMobile onButtonClick={onClick} />
            ) : (
                <ProgressPathDesktop onButtonClick={onClick} />
            )}
        </div>
    );
};

export default memo(ProgressPath);
