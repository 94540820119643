import { atom, useRecoilState } from 'recoil';

const soundConfigState = atom<{ picked: boolean; enabled: boolean }>({
    key: 'soundConfigState',
    default: {
        picked: false,
        enabled: false,
    },
});

export const useSoundConfigState = () => useRecoilState(soundConfigState);
