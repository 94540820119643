import { useProgress } from '@react-three/drei';
import { useCountUp } from 'react-countup';
import classNames from 'classnames';
import { usePreloaderReadyState } from '../../../atoms/preloader-ready';
import { useDebounce } from '../../../hooks/use-debounce';
import { useEffect, useRef } from 'react';
import { supportsWebp } from '../../../utils/supports-webp';
import { useGpuState } from '../../../atoms/gpu';

const Preloader = () => {
    const counterRef = useRef<HTMLDivElement>(null);

    const progress = useProgress((state) => state.progress);
    const debouncedProgress = useDebounce(progress, 1300);
    const [_, setPreloaderReady] = usePreloaderReadyState();
    const [gpuState] = useGpuState();

    const { update } = useCountUp({
        ref: counterRef,
        start: 0,
        end: 0,
        duration: 1,
        useEasing: true,
    });

    useEffect(() => {
        if (gpuState) {
            update(progress);
        }
    }, [update, gpuState, progress]);

    useEffect(() => {
        if (debouncedProgress === 100) {
            setPreloaderReady(true);
        }
    }, [debouncedProgress, setPreloaderReady]);

    return (
        <div
            className={classNames('preloader', {
                'preloader--leaving': debouncedProgress === 100,
            })}
        >
            <div className="preloader-inner-el">
                <img
                    src={`/img/paints/orange.${supportsWebp ? 'webp' : 'jpg'}`}
                    width={1920}
                    height={960}
                    alt=""
                    className="preloader-bg"
                />
                <img
                    src={`/img/happy-ny-white.${supportsWebp ? 'webp' : 'png'}`}
                    width={716}
                    height={560}
                    alt=""
                    className="img-fluid preloader-img"
                />
                <div className="preloader-bottom">
                    <div className="preloader-progress">
                        <span ref={counterRef}>0</span>%
                    </div>
                    <div className="preloader-progressbar">
                        <div
                            className="preloader-progressbar__item"
                            style={{
                                transform: `scaleX(${
                                    Math.round(progress) / 100
                                })`,
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preloader;
