import { useEffect, useRef } from 'react';
import round from 'lodash.round';
import MouseSVG from '../../../svg/mouse.svg';

const length = round(313.6517333984375, 3);

const ProgressRound = () => {
    const circle = useRef<SVGCircleElement>(null);

    useEffect(() => {
        let rAF = 0;

        const fn = () => {
            circle.current?.setAttribute(
                'stroke-dashoffset',
                `${round(
                    length * (1 - (window as any)._scrollOffset || 0),
                    3,
                )}`,
            );
            rAF = requestAnimationFrame(fn);
        };

        fn();

        return () => {
            cancelAnimationFrame(rAF);
        };
    }, []);

    return (
        <div className="progress-round">
            <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                className="progress-round__el"
            >
                <circle
                    ref={circle}
                    r="50"
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeDasharray={`${length} ${length}`}
                ></circle>
            </svg>
            <span className="progress-round__icon">
                <MouseSVG />
            </span>
        </div>
    );
};

export default ProgressRound;
