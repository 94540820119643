import { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import {
    // OrbitControls,
    PerformanceMonitor,
    ScrollControls,
    Stats,
    Preload,
} from '@react-three/drei';
import round from 'lodash.round';
import { TierResult } from 'detect-gpu';
import Model from './ModelNew';
import Particles from './Particles';
import { data } from '../../data';
import Postprocessing from './Postprocessing';
import { useSoundConfigState } from '../../atoms/sound-config';
// import Rig from './Rig';
import FlyingCamera from './FlyingCamera';
import { useGpuState } from '../../atoms/gpu';
import { useScrollControlsState } from '../../atoms/scroll-controls';
import { WebGLRenderer } from 'three';

const shouldUsePostprocessingBasedOnGPUState = ({
    isMobile,
    fps,
    tier,
    gpu,
}: TierResult) => {
    if (
        isMobile ||
        tier < 3 ||
        gpu === 'intel hd graphics 620' ||
        (typeof fps === 'number' ? fps < 50 : true)
    ) {
        return false;
    }

    return true;
};

const totalScreens = Object.keys(data).length;

const WebGL = () => {
    const [gpuState] = useGpuState();
    const [dpr, setDpr] = useState(Math.min(devicePixelRatio, 1.25));
    const [usePostprocessing] = useState(
        gpuState ? shouldUsePostprocessingBasedOnGPUState(gpuState) : false,
    );
    const [soundConfig] = useSoundConfigState();
    const [scrollEnabled] = useScrollControlsState();
    const appReady = soundConfig.picked;

    return (
        <div className="canvas-wrapper">
            <Canvas
                camera={{
                    position: [193, 78, 20],
                    fov: 32.27,
                    near: 10,
                    far: 1000,
                }}
                dpr={dpr}
                gl={(canvas) =>
                    new WebGLRenderer({
                        canvas,
                        antialias:
                            !usePostprocessing &&
                            (gpuState && typeof gpuState.fps === 'number'
                                ? gpuState.fps > 40
                                : false),
                    })
                }
                flat
                frameloop={appReady ? 'always' : 'demand'}
            >
                <color attach="background" args={['black']} />

                {/* {appReady && <Rig to={toVec} lookAt={cameraLookAtVec} />} */}
                <Particles count={500} position={[0, 100, 0]} />
                <Model />
                <Preload all />

                <ScrollControls
                    pages={totalScreens}
                    // infinite
                    distance={3}
                    damping={4}
                    enabled={appReady && scrollEnabled}
                >
                    <FlyingCamera />
                </ScrollControls>
                {/* <OrbitControls /> */}

                <PerformanceMonitor
                    onChange={({ factor }) =>
                        setDpr(
                            Math.min(
                                Math.min(devicePixelRatio, 2),
                                round(0.75 + 1.25 * factor, 1),
                            ),
                        )
                    }
                >
                    <Postprocessing enabled={usePostprocessing} />
                </PerformanceMonitor>
                {process.env.NODE_ENV === 'development' && <Stats />}
            </Canvas>

            <img
                src="/light-opt.png"
                alt=""
                className="light"
                decoding="async"
                loading="lazy"
            />
        </div>
    );
};

export default WebGL;
