import classNames from 'classnames';
import { useActiveLetterState } from '../../../atoms/active-letter';
import { useSoundConfigState } from '../../../atoms/sound-config';
import ChipsaLogoSVG from '../../../svg/logo.svg';
import RoundButton from '../RoundButton';
import { useOpenedPopupsState } from '../../../atoms/opened-popups';

const Header = () => {
    const [soundConfig, setSoundConfig] = useSoundConfigState();
    const [activeLetterName] = useActiveLetterState();
    const [openedPopups] = useOpenedPopupsState();

    return (
        <header className="header wrapper pointer-events-none">
            <div className="header__left">
                <a
                    href="https://chipsa.ru/"
                    target="_blank"
                    rel="noreferrer"
                    className="logo pointer-events-auto"
                    aria-label="Перейти на сайт Chipsa"
                >
                    <ChipsaLogoSVG />
                </a>
            </div>
            <div className="header__right">
                <RoundButton
                    aria-label="Звук"
                    className={classNames(
                        'header-btn sound-toggler-btn pointer-events-auto',
                        {
                            'sound-toggler-btn--enabled': soundConfig.enabled,
                        },
                    )}
                    onClick={() =>
                        setSoundConfig((prevConfig) => ({
                            ...prevConfig,
                            enabled: !prevConfig.enabled,
                        }))
                    }
                    data-sound="click"
                >
                    <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect y="4" width="2" height="11" fill="#F2F2F2"></rect>
                        <rect x="4" width="2" height="15" fill="#F2F2F2"></rect>
                        <rect
                            x="8"
                            y="7"
                            width="2"
                            height="8"
                            fill="#F2F2F2"
                        ></rect>
                        <rect
                            x="12"
                            y="2"
                            width="2"
                            height="13"
                            fill="#F2F2F2"
                        ></rect>
                    </svg>
                </RoundButton>
                {activeLetterName ? (
                    <RoundButton
                        aria-label="Закрыть"
                        size="lg"
                        className={classNames(
                            'menu-btn header-btn pointer-events-auto',
                            {
                                'menu-btn--opened': activeLetterName,
                            },
                        )}
                        data-lit-popup-close="letter"
                        data-sound="click"
                    >
                        <span></span>
                        <span></span>
                    </RoundButton>
                ) : (
                    <RoundButton
                        aria-label="Меню"
                        size="lg"
                        className={classNames(
                            'menu-btn header-btn pointer-events-auto',
                            {
                                'menu-btn--opened':
                                    openedPopups.includes('menu'),
                            },
                        )}
                        data-sound="click"
                        data-lit-popup-open={
                            !openedPopups.includes('menu') ? 'menu' : undefined
                        }
                        data-lit-popup-close={
                            openedPopups.includes('menu') ? 'menu' : undefined
                        }
                    >
                        <span></span>
                        <span></span>
                    </RoundButton>
                )}
            </div>
        </header>
    );
};

export default Header;
