import classNames from 'classnames';
import { Howl } from 'howler';
import { HTMLAttributes, memo, useEffect, useState } from 'react';
import { useActiveLetterState } from '../../../atoms/active-letter';
import { useSoundConfigState } from '../../../atoms/sound-config';
import { supportsWebp } from '../../../utils/supports-webp';
import LitPopup from '../LitPopup';

export interface Props extends HTMLAttributes<HTMLElement> {
    opened?: boolean;
    from?: string;
    to?: string;
    text?: string;
    logoUrl?: string;
}

const LetterPopup = ({
    opened = false,
    from,
    to,
    text,
    logoUrl,
    ...props
}: Props) => {
    const [_, setActiveLetterName] = useActiveLetterState();
    const [soundConfig] = useSoundConfigState();
    const [letterOpeningSound] = useState<Howl>(
        () =>
            new Howl({
                src: ['/sounds/letter-opening-new.wav'],
                volume: 1,
            }),
    );

    useEffect(() => {
        if (soundConfig.enabled && opened) {
            letterOpeningSound.play();
        }
    }, [opened, letterOpeningSound, soundConfig.enabled]);

    return (
        <LitPopup
            {...props}
            className={classNames('letter-popup', props.className)}
            name="letter"
            opened={opened}
            onClose={() => setActiveLetterName(null)}
            wrapperChildren={
                <img
                    src={`/img/paints/blue.${supportsWebp ? 'webp' : 'jpg'}`}
                    width={1920}
                    height={960}
                    alt=""
                    className="letter-popup__bg"
                    loading="lazy"
                    decoding="async"
                />
            }
        >
            <div className="letter-popup-content-wrapper">
                <img
                    src={`/img/paints/beige.${supportsWebp ? 'webp' : 'jpg'}`}
                    width={992}
                    height={816}
                    alt=""
                    className="letter__bg"
                    loading="lazy"
                    decoding="async"
                />
                <img
                    src="/img/letter-border.png"
                    width={953}
                    height={777}
                    alt=""
                    className="letter__border"
                    loading="lazy"
                    decoding="async"
                />
                <div className="letter-inner">
                    {text && (
                        <div
                            className="letter__text"
                            dangerouslySetInnerHTML={{ __html: text }}
                        ></div>
                    )}
                    {logoUrl && (
                        <div className="letter__logo">
                            <img
                                src="/img/letter-logo-border.png"
                                width={208}
                                height={156}
                                alt=""
                                className="letter__logo-border"
                                loading="lazy"
                                decoding="async"
                            />
                            <img
                                src={logoUrl}
                                width={105}
                                height={24}
                                alt=""
                                className="img-fluid"
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                    )}
                    <div className="letter-happy-container">
                        <img
                            src="/happy-new-year.png"
                            width={1027}
                            height={582}
                            alt=""
                            className="img-fluid letter-happy"
                            loading="lazy"
                            decoding="async"
                        />
                    </div>
                    <div className="letter-bottom">
                        {to && (
                            <div className="letter-bottom__item">
                                <span>Кому:</span>
                                <span className="letter-bottom__item--underlined">
                                    {to}
                                </span>
                            </div>
                        )}
                    </div>
                    <img
                        src="/img/letter-chipsa.png"
                        width={676}
                        height={677}
                        alt=""
                        className="img-fluid letter-chipsa"
                        loading="lazy"
                        decoding="async"
                    />
                </div>
            </div>
        </LitPopup>
    );
};

export default memo(LetterPopup);
