import { Howl } from 'howler';
import { useEffect, useState } from 'react';
// @ts-ignore
import delegate from 'delegate';
import { useSoundConfigState } from '../../../atoms/sound-config';

const Sounds = () => {
    const [soundConfig] = useSoundConfigState();
    const [ambientSound] = useState<Howl>(
        () =>
            new Howl({
                src: ['/sounds/ambience.mp3'],
                loop: true,
            }),
    );
    const [clickSound] = useState<Howl>(
        () =>
            new Howl({
                src: ['/sounds/click-new.wav'],
                volume: 0.75,
            }),
    );

    /**
     * Музыка на фоне
     */
    useEffect(() => {
        if (soundConfig.enabled) {
            ambientSound.play();
            ambientSound.fade(0, 1, 500);
        } else {
            ambientSound.pause();
        }

        return () => {
            ambientSound.pause();
        };
    }, [ambientSound, soundConfig.enabled]);

    /**
     * Мелодия кликов
     */
    useEffect(() => {
        const delegation = delegate(
            document,
            '[data-sound="click"]',
            'click',
            () => {
                if (soundConfig.enabled) {
                    clickSound.play();
                }
            },
        );

        return () => delegation.destroy();
    }, [clickSound, soundConfig.enabled]);

    return null;
};

export default Sounds;
