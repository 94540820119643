import classNames from 'classnames';
import { Howl } from 'howler';
import { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import { useSoundConfigState } from '../../../atoms/sound-config';
import { supportsWebp } from '../../../utils/supports-webp';

interface Props extends HTMLAttributes<HTMLElement> {
    title: string;
    text: string;
    bgUrl?: string;
    classPostfix: string;
    visible?: boolean;
}

const Message = forwardRef<HTMLDivElement, Props>(
    (
        {
            title,
            text,
            bgUrl = `/img/message-bg.${supportsWebp ? 'webp' : 'png'}`,
            classPostfix,
            visible = false,
            ...props
        },
        ref,
    ) => {
        const [soundConfig] = useSoundConfigState();
        const [revealSound] = useState<Howl>(
            () =>
                new Howl({
                    src: ['/sounds/message-reveal-new.wav'],
                    volume: 1,
                }),
        );

        useEffect(() => {
            if (soundConfig.enabled && visible) {
                revealSound.play();
            }
        }, [visible, revealSound, soundConfig.enabled]);

        return (
            <div
                {...props}
                ref={ref}
                className={classNames(
                    'message',
                    `message-${classPostfix}`,
                    props.className,
                    { 'is-visible': visible },
                )}
            >
                <img
                    src={bgUrl}
                    className="message__bg"
                    alt=""
                    loading="lazy"
                    decoding="async"
                />
                <div className="message__title">{title}</div>
                <div
                    className="message__text"
                    dangerouslySetInnerHTML={{ __html: text }}
                ></div>
            </div>
        );
    },
);

Message.displayName = 'Message';

export default Message;
