import React from 'react';
import classNames from 'classnames';

export interface Props
    extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
    /**
     * Какой тег рендерить
     */
    tag?: keyof JSX.IntrinsicElements;
    /**
     * Цветовая тема кнопки
     */
    variant?: 'default';
    /**
     * Размер
     */
    size?: 'md' | 'lg';
}

const RoundButton = React.forwardRef<HTMLElement, Props>(
    (
        { children, variant = 'default', tag = 'button', size, ...props },
        ref,
    ) => {
        return React.createElement(
            tag,
            {
                ...props,
                ref,
                className: classNames(
                    'round-btn',
                    `round-btn-${variant}`,
                    size ? `round-btn-${size}` : '',
                    props.className,
                ),
            },
            children,
        );

        // return (
        //     <Tag
        //         {...props}
        //         ref={ref}
        //         className={classNames(
        //             'round-btn',
        //             `round-btn-${variant}`,
        //             size ? `round-btn-${size}` : '',
        //             props.className,
        //         )}
        //     >
        //         {children}
        //     </Tag>
        // );
    },
);

RoundButton.displayName = 'RoundButton';

export default RoundButton;
