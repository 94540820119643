/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei';
import { MathUtils } from 'three';
import { MeshProps, useFrame, useLoader, useThree } from '@react-three/fiber';
import { memo, useEffect, useRef, useState } from 'react';
import { EquirectangularReflectionMapping, Mesh, RGBAFormat } from 'three';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { useActiveLetterState } from '../../atoms/active-letter';
import { useScreensState } from '../../atoms/screens';
import Sparkles from './Sparkler/Sparkle';

const MODEL_URL = '/s/try2-opt.glb';
const USE_DRACO = true;
const USE_MESHOPT = false;

const extendLoader = (loader: any) => {
    const ktxLoader = new KTX2Loader().setTranscoderPath('/basis/');
    loader.setKTX2Loader(ktxLoader);
    return loader;
};

const InteractiveMesh = ({
    name,
    material,
    hoveredCardName,
    setHoveredCardName,
    ...props
}: MeshProps & {
    hoveredCardName: string | null;
    setHoveredCardName: React.Dispatch<React.SetStateAction<string | null>>;
    geometry: any;
    material: any;
    name: string;
}) => {
    const [_, setActiveLetterName] = useActiveLetterState();
    const [currentScreen] = useScreensState();

    const shouldRaycast = currentScreen >= 7 && currentScreen <= 12;

    return (
        <mesh
            {...props}
            name={name}
            onPointerOver={
                shouldRaycast ? () => setHoveredCardName(name) : undefined
            }
            onPointerOut={
                shouldRaycast ? () => setHoveredCardName(null) : undefined
            }
            onClick={
                shouldRaycast ? () => setActiveLetterName(name) : undefined
            }
        >
            <meshBasicMaterial
                color={hoveredCardName === name ? '#ddd' : '#fff'}
                map={material.map}
            />
        </mesh>
    );
};

let _some = 0;

function Model() {
    const chipsinka = useRef<Mesh>(null);

    const [hoveredCardName, setHoveredCardName] = useState<string | null>(null);
    const glassEnvMap = useLoader(
        RGBELoader,
        '/img/christmas_photo_studio_01_1k.hdr',
    );
    glassEnvMap.mapping = EquirectangularReflectionMapping;
    glassEnvMap.format = RGBAFormat;
    // glassEnvMap.type = UnsignedByteType;
    const { gl } = useThree();

    useEffect(() => {
        const { current } = chipsinka;

        if (current && _some === 0) {
            _some++;
            current.geometry.rotateX(MathUtils.degToRad(145));
            current.geometry.rotateY(MathUtils.degToRad(180));
            current.geometry.rotateZ(MathUtils.degToRad(5));
        }
    }, []);

    useFrame(({ camera }) => {
        // 2.82 2.58 4.68
        if (chipsinka.current) {
            chipsinka.current.lookAt(camera.position);
        }
    });

    useEffect(() => {
        document.documentElement.style.cursor = !!hoveredCardName
            ? 'pointer'
            : '';
    }, [hoveredCardName]);

    const { nodes, materials } = useGLTF(
        MODEL_URL,
        USE_DRACO,
        USE_MESHOPT,
        extendLoader,
    ) as any;

    const clients: {
        name: string;
        material: any;
        position: [number, number, number];
        rotation: [number, number, number];
    }[] = [
        {
            name: '0101',
            material: materials['Mat.22'],
            position: [-9.89, 0.01, -13.51],
            rotation: [-Math.PI, 0, -Math.PI],
        },
        {
            name: 'A101',
            material: materials['Mat.35'],
            position: [4.24, 0.01, -5.11],
            rotation: [-Math.PI, 0.46, -Math.PI],
        },
        {
            name: 'AAG',
            material: materials['Mat.23'],
            position: [22.95, 0, -8.93],
            rotation: [-Math.PI, 0.77, -Math.PI],
        },
        {
            name: 'Art-life',
            material: materials['Mat.36'],
            position: [36.52, 0, 6.34],
            rotation: [-Math.PI, 0.73, -Math.PI],
        },
        {
            name: 'Bati',
            material: materials['Mat.24'],
            position: [6.8, 0, -10.56],
            rotation: [-Math.PI, 0.13, -Math.PI],
        },
        {
            name: 'Binar',
            material: materials['Mat.37'],
            position: [24.19, 0, 15.16],
            rotation: [-Math.PI, 0.47, -Math.PI],
        },
        {
            name: 'Charruti',
            material: materials['Mat.25'],
            position: [16.02, 0, -17.09],
            rotation: [-Math.PI, 0.33, -Math.PI],
        },
        {
            name: 'Composit',
            material: materials['Mat.45'],
            position: [43.82, 0, 20.16],
            rotation: [-Math.PI, 0.91, -Math.PI],
        },
        {
            name: 'Daigo',
            material: materials['Mat.26'],
            position: [4.77, 0, -19.95],
            rotation: [-Math.PI, 0.11, -Math.PI],
        },
        {
            name: 'Darkside',
            material: materials['Mat.27'],
            position: [26.68, 0, -15.92],
            rotation: [-Math.PI, 0.65, -Math.PI],
        },
        {
            name: 'Devision',
            material: materials['Mat.28'],
            position: [12.2, 0, -28.48],
            rotation: [Math.PI, -0.14, Math.PI],
        },
        {
            name: 'Dianet',
            material: materials['Mat.42'],
            position: [0.19, 0, -27.87],
            rotation: [-Math.PI, 0.38, -Math.PI],
        },
        {
            name: 'Dipol',
            material: materials['Mat.43'],
            position: [33.51, 0, 21.06],
            rotation: [-Math.PI, 0.97, -Math.PI],
        },
        {
            name: 'Dvijenie',
            material: materials['Mat.41'],
            position: [11.23, 0, 0.75],
            rotation: [-Math.PI, 0.47, -Math.PI],
        },
        {
            name: 'Eldis',
            material: materials['Mat.55'],
            position: [41.14, 0, -9.74],
            rotation: [-Math.PI, 1.21, -Math.PI],
        },
        {
            name: 'Gt-energo',
            material: materials['Mat.38'],
            position: [34.38, 0.01, 14.92],
            rotation: [-Math.PI, 0.98, -Math.PI],
        },
        {
            name: 'Gurmix',
            material: materials['Mat.39'],
            position: [-10.49, 0, -26.4],
            rotation: [-Math.PI, 0.13, -Math.PI],
        },
        {
            name: 'IIko',
            material: materials['Mat.29'],
            position: [28, 0, -1.82],
            rotation: [-Math.PI, 0.65, -Math.PI],
        },
        {
            name: 'JSK',
            material: materials['Mat.44'],
            position: [13.45, 0, 9.58],
            rotation: [-Math.PI, 0.88, -Math.PI],
        },
        {
            name: 'Malinovka',
            material: materials['Mat.40'],
            position: [-8.02, 0, -1.79],
            rotation: [-Math.PI, 0.41, -Math.PI],
        },
        {
            name: 'MDIS',
            material: materials['Mat.46'],
            position: [-15.83, 0, -5.17],
            rotation: [-Math.PI, 0.53, -Math.PI],
        },
        {
            name: 'NJC',
            material: materials['Mat.47'],
            position: [-7.6, 0, -19.8],
            rotation: [Math.PI, 0, Math.PI],
        },
        {
            name: 'ORION',
            material: materials['Mat.49'],
            position: [-23.29, 0, -11.1],
            rotation: [-Math.PI, 0.45, -Math.PI],
        },
        {
            name: 'OS',
            material: materials['Mat.48'],
            position: [15.13, 0, -5.32],
            rotation: [-Math.PI, 0.52, -Math.PI],
        },
        {
            name: 'Osmo_Legal',
            material: materials['Mat.30'],
            position: [34.69, 0, -17.41],
            rotation: [-Math.PI, 0.62, -Math.PI],
        },
        {
            name: 'Paper_Planes',
            material: materials['Mat.31'],
            position: [19.74, 0, -22.88],
            rotation: [-Math.PI, 0.44, -Math.PI],
        },
        {
            name: 'RAY',
            material: materials['Mat.51'],
            position: [-2.79, 0, -12.88],
            rotation: [-Math.PI, 0.36, -Math.PI],
        },
        {
            name: 'RSI',
            material: materials['Mat.50'],
            position: [3.36, 0, 4.39],
            rotation: [-Math.PI, 0.43, -Math.PI],
        },
        {
            name: 'SGC',
            material: materials['Mat.52'],
            position: [47.01, 0, 7.8],
            rotation: [-Math.PI, 0.76, -Math.PI],
        },
        {
            name: 'Sheregesh',
            material: materials['Mat.54'],
            position: [38.65, 0, -1.27],
            rotation: [-Math.PI, 0.97, -Math.PI],
        },
        {
            name: 'Skinstric',
            material: materials['Mat.32'],
            position: [33.17, 0, -8.95],
            rotation: [-Math.PI, 0.57, -Math.PI],
        },
        {
            name: 'Sportex',
            material: materials['Mat.53'],
            position: [48.54, 0, 16.82],
            rotation: [-Math.PI, 0.8, -Math.PI],
        },
        {
            name: 'TBD',
            material: materials['Mat.33'],
            position: [19.29, 0, 4.21],
            rotation: [-Math.PI, 0.81, -Math.PI],
        },
        {
            name: 'Treaton',
            material: materials['Mat.34'],
            position: [27.34, 0, 7.82],
            rotation: [-Math.PI, 0.85, -Math.PI],
        },
    ];

    return (
        <group dispose={null}>
            <group position={[-17.23, 13.27, 36.79]}>
                <group
                    position={[15.26, -13.2, 39.68]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                >
                    <group position={[-8.83, 0, 3.72]} rotation={[0, 0.31, 0]}>
                        {clients.map((client, i) => (
                            <InteractiveMesh
                                key={i}
                                name={client.name}
                                geometry={nodes['0101'].geometry}
                                material={client.material}
                                position={client.position}
                                rotation={client.rotation}
                                hoveredCardName={hoveredCardName}
                                setHoveredCardName={setHoveredCardName}
                            />
                        ))}
                    </group>
                </group>
                <group position={[11.33, 1.29, -22.66]}>
                    <mesh
                        name="Apelsin"
                        geometry={nodes.Apelsin.geometry}
                        position={[0.18, -2.83, 14.78]}
                        rotation={[-0.22, -0.33, -0.04]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat'].map} />
                    </mesh>

                    <mesh
                        name="coockie"
                        geometry={nodes.coockie.geometry}
                        position={[-25.93, -13.75, 37.43]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.8'].map} />
                    </mesh>
                    <mesh
                        name="lambert2SG"
                        geometry={nodes.lambert2SG.geometry}
                        position={[14.5, -2.06, 17.55]}
                        rotation={[-0.1, 0.79, -0.33]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.57'].map} />
                    </mesh>
                    <mesh
                        name="Tree_snow"
                        geometry={nodes.Tree_snow.geometry}
                        position={[9.41, 3.98, 23.78]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.21'].map} />
                    </mesh>
                    <mesh
                        name="Mishura1"
                        geometry={nodes.Mishura1.geometry}
                        position={[5.9, -9.53, -14.13]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.12'].map} />
                    </mesh>
                    <mesh
                        name="Bengal"
                        geometry={nodes.Bengal.geometry}
                        position={[13.18, 1.44, 37.32]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.19'].map} />
                    </mesh>
                    <Sparkles position={[10.9, 11.44, 37.32]} amp={1} />
                    <Sparkles
                        position={[17.5, 12.44, 41.32]}
                        size={1.5}
                        amp={0.8}
                    />
                    <mesh
                        name="Cloner3"
                        geometry={nodes.Cloner3.geometry}
                        position={[22.3, -13.34, -3.36]}
                        rotation={[-0.01, 0, 0]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.10'].map} />
                    </mesh>
                    <mesh
                        name="Chocolade"
                        geometry={nodes.Chocolade.geometry}
                        position={[-11.82, -10.4, -10.21]}
                        rotation={[-3.13, 0.02, -2.18]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.13'].map} />
                    </mesh>
                    <mesh
                        name="Cloner4"
                        geometry={nodes.Cloner4.geometry}
                        position={[5.32, -10.73, 7.96]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.9'].map} />
                    </mesh>
                    <mesh
                        name="Instance_Pryanik1"
                        geometry={nodes.Instance_Pryanik1.geometry}
                        position={[-7.1, -3.99, 17.98]}
                        rotation={[0.81, -0.68, 1.67]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.16'].map} />
                    </mesh>
                    <mesh
                        ref={chipsinka}
                        name="Remesh002"
                        geometry={nodes.Remesh002.geometry}
                        position={[9.31, 12.6, 23.83]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={nodes.Remesh002.material.map} />
                    </mesh>
                    <mesh
                        name="Snow_coin"
                        geometry={nodes.Snow_coin.geometry}
                        position={[-3.8, -3.48, 3.63]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.5'].map} />
                    </mesh>
                    <mesh
                        name="Sphere"
                        geometry={nodes.Sphere.geometry}
                        material={materials['Mat.20']}
                        position={[9.49, 7.64, 23.65]}
                        raycast={() => {}}
                    >
                        <meshPhysicalMaterial
                            metalness={0}
                            roughness={0.06}
                            transmission={0.995}
                            thickness={0.5}
                            clearcoat={0.5}
                            clearcoatRoughness={0.1}
                            // metalness={metalness}
                            // roughness={roughness}
                            // transmission={transmission}
                            // thickness={thickness}
                            // clearcoat={clearcoat}
                            // clearcoatRoughness={clearcoatRoughness}
                            envMapIntensity={0.5}
                            envMap={glassEnvMap}
                        />
                    </mesh>
                    {!gl.capabilities.isWebGL2 && (
                        <directionalLight /* visible={false} */ />
                    )}
                    <mesh
                        name="Stage"
                        geometry={nodes.Stage.geometry}
                        material={materials['Mat.3']}
                        position={[9.49, -0.28, 23.65]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.3'].map} />
                    </mesh>
                    <mesh
                        name="Instance_Pryanik2"
                        geometry={nodes.Instance_Pryanik2.geometry}
                        position={[-5.47, -5.44, 12.12]}
                        rotation={[0.09, 0.17, 0.68]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.15'].map} />
                    </mesh>
                    <mesh
                        name="Tarelka"
                        geometry={nodes.Tarelka.geometry}
                        position={[-26.77, -14.03, 33.68]}
                        rotation={[Math.PI, -0.53, Math.PI]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.18'].map} />
                    </mesh>
                    <mesh
                        name="Tree"
                        geometry={nodes.Tree.geometry}
                        position={[9.41, 3.98, 23.78]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.6'].map} />
                    </mesh>
                    <mesh
                        name="Remesh1"
                        geometry={nodes.Remesh1.geometry}
                        position={[-5.78, -7.11, -18.18]}
                    >
                        <meshBasicMaterial map={materials['Mat.60'].map} />
                    </mesh>
                    <mesh
                        name="Chokolade"
                        geometry={nodes.Chokolade.geometry}
                        position={[-9.76, -6.91, -10.22]}
                        rotation={[0.8, 0.93, 0.68]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.14'].map} />
                    </mesh>
                    <mesh
                        name="Fill"
                        geometry={nodes.Fill.geometry}
                        position={[-9.37, -0.32, -17.36]}
                        rotation={[1.34, 0.47, -0.62]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.7'].map} />
                    </mesh>
                    <mesh
                        name="Instance_Pryanik"
                        geometry={nodes.Instance_Pryanik.geometry}
                        position={[-2.86, -6.39, 8.44]}
                        rotation={[1.95, 0.4, -2.11]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.17'].map} />
                    </mesh>
                    <mesh
                        name="Remesh"
                        geometry={nodes.Remesh.geometry}
                        position={[4.51, -9.83, 7.25]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.1'].map} />
                    </mesh>
                    <mesh
                        name="Spehers"
                        geometry={nodes.Spehers.geometry}
                        material={materials['Mat.11']}
                        position={[19.53, -11.73, 13.95]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.11'].map} />
                    </mesh>
                    <mesh
                        name="starAniseobj_Instance"
                        geometry={nodes.starAniseobj_Instance.geometry}
                        position={[17.38, -2.14, 20.67]}
                        raycast={() => {}}
                    >
                        <meshBasicMaterial map={materials['Mat.4'].map} />
                    </mesh>
                    <mesh
                        position={[0, -14.6, 40]}
                        rotation={[-Math.PI / 2, 0, 0]}
                        raycast={() => {}}
                    >
                        <planeBufferGeometry args={[200, 200, 1, 1]} />
                        <meshBasicMaterial color="#000" />
                    </mesh>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload(MODEL_URL, USE_DRACO, USE_MESHOPT, extendLoader);

export default memo(Model);
